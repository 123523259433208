<template>
  <div class="h-full">
    <div class="mx-auto mt-32 lg:w-4/12 py-10 border border-gray-400 p-10 rounded-2xl">
      <h1 class="text-center text-4xl mb-10">Login</h1>
      <label class="relative py-5" for="username" :class="{ error: errors.username}">
        <span>Username</span>
        <input
          class="w-full border-b border-gray-400 mt-2 mb-12 outline-none"
          type="text"
          id="username"
          v-model="user.username"
          @keyup.enter="auth"
        />
        <span v-if="errors.username" class="absolute bottom-0 left-0 h-5">
          This field is required
        </span>
      </label>
      <label class="relative py-5" for="password" :class="{ error: errors.password}">
        <span>Password</span>
        <input
          class="w-full border-b border-gray-400 mt-2 mb-4 outline-none"
          type="password"
          id='password'
          v-model="user.password"
          @keyup.enter="auth"
        />
        <span v-if="errors.password" class="absolute bottom-0 left-0 h-5">
          This field is required
        </span>
      </label>
      <div class="relative py-5 mb-8" :class="{ error: errors.token}">
        <vue-recaptcha ref="recaptcha" class="w-full flex justify-center" @verify="verifyMethod" sitekey="6LfqMK0gAAAAAKCiHvelp5zY2rMpF-kvmE-mgeiX" />
        <span v-if="errors.token" class="absolute bottom-0 left-0 h-5 w-full flex justify-center">
          This field is required
        </span>
      </div>
      <div class="flex justify-center text-white">
        <button class="bg-blue-400 text-lg rounded-xl px-4 py-2" @click="auth">
          Login
        </button>
      </div>
      <div class="w-full text-red-500 text-center" v-if="authFail">Invalid credentials.</div>
    </div>
  </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  name: 'Login',
  components: { VueRecaptcha },
  data () {
    return {
      user: {
        username: '',
        password: '',
        token: ''
      },
      errors: [],
      authFail: false
    }
  },
  created () {
    this.$emit('loading', false)
  },
  methods: {
    verifyMethod (res) {
      this.user.token = res
    },
    checkValidation () {
      for (const k in this.user) {
        if (!this.user[k] || !this.user[k].trim()) this.errors[k] = true
        else delete this.errors[k]
        this.$forceUpdate()
      }
      if (Object.keys(this.errors).length) {
        this.authFail = false
        return false
      }
      return true
    },
    auth () {
      if (this.checkValidation()) {
        this.$refs.recaptcha.reset()
        this.$store.dispatch('login', this.user)
          .then(() => this.$router.push('/'))
          .catch(err => this.handleError(err))
      }
    },
    handleError (error) {
      console.log(error)
      this.authFail = true
    }
  }
}

</script>

<style>
</style>
